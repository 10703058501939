import styles from "./styles.module.scss";
import useTranslation from "next-translate/useTranslation";
import Star from "../../../common/elements/Star";
import {Button, ButtonGroup} from "@shopify/polaris";
import Image from "next/image";
import appdev from "/public/home/appdev/appdev.png";
import appdev_horiz from "/public/home/appdev/appdev-horiz.jpg";
import {
  StoreMajor, ThemesMajor
} from '@shopify/polaris-icons';
import {APP_STORE_LINK} from "../../../../utils/constants";
import {HOME_NAMESPACE} from "../../../../utils/i18nNameSpaces";

const AppDev = () => {
  const {t} = useTranslation(HOME_NAMESPACE);

  return (
    <div className={styles["appdev"]}>
      <div className={styles["appdev__container"]}>
        <div className={styles["appdev__content"]}>
          <div className={styles["appdev__header-wrapper"]}>
            <h3 className={styles["appdev__header"]}>{t("appdev.header")}</h3>
            <p className={styles["appdev__subheader"]}>{t("appdev.subheader")}</p>
          </div>
          <section className={styles["appdev__info"]}>
            <span className={styles["appdev__content-label"]}>5+</span>
            <div>
              <h4 className={styles["appdev__content-header"]}>{t("appdev.content.header")}</h4>
              <p className={styles["appdev__content-subheader"]}>{t("appdev.content.subheader")}</p>
            </div>
            <footer className={styles["appdev__content-footer"]}>
              <div className={styles["appdev__rating"]}>
                <span className={styles["appdev__rating-text"]}>{t("appdev.content.rating")}</span>
                <div className={styles["star-wrapper"]}>
                  <Star starStyles={{width: 10, height: 10}}/>
                </div>
                <span className={styles["appdev__rating-value"]}>5.0</span>
              </div>
              <div className={styles["appdev__review"]}>
                <p className={styles["appdev__review-text"]}>{t("appdev.content.review")} <span
                  className={styles["appdev__review-total"]}>1,000+</span></p>
              </div>
            </footer>
          </section>
          <footer className={styles["appdev__footer"]}>
            <ButtonGroup>
              <Button icon={StoreMajor} url={APP_STORE_LINK} external primary>{t("appdev.linkPrimary")}</Button>
              <Button icon={ThemesMajor} url="/apps">{t("appdev.linkBasic")}</Button>
            </ButtonGroup>
          </footer>
        </div>
        <div className={styles["appdev__img-wrapper"]}>
          <div className={styles["appdev__img--desc"]}>
            <Image
              src={appdev}
              quality={100}
              layout="responsive"
              alt="App dev"
            />
          </div>
          <div className={styles["appdev__img--mobile"]}>
            <Image
              src={appdev_horiz}
              quality={100}
              layout="responsive"
              alt="App dev"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDev;