import React from 'react';

const Star = ({color = '#ffc20a', starStyles, className}) => (
  <span className={className} style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...starStyles
  }}>
    <svg width="100%" height="100%" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2084 15.6146L6.95413 13.1704L2.61803 15.5206L3.35435 10.0681L0 6.11976L4.74517 5.27368L6.95413 0.385254L9.16309 5.27368L13.9083 6.21377L10.5539 10.1621L11.2084 15.6146Z"
        fill={color}/>
    </svg>
    </span>
);

export default Star;